import React, { memo } from "react";
import { Options } from "react-select";
import 
  LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps
} from "../labeledInput";
import
  XGSSelect, {
  XGSSelectOption,
  GroupedOption,
  XGSSelectProps
} from "../../../xgs-select/xgsSelect";

export interface LabeledSelectInputProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    XGSSelectProps {
  options: Options<XGSSelectOption> | GroupedOption[];
}

const LabeledSelectInput: React.FC<LabeledSelectInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  fields,
  meta,
  error,
  className,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={className || ""}
    >
      <XGSSelect className={`${isFailed() ? "not-valid" : ""}`} {...props} />
    </LabeledInput>
  );
});

export default LabeledSelectInput;